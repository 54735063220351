import { v4 as uuidv4 } from "uuid";
import getCookie from "@utilities/getCookie";

// FORMS API SERVICE
const baseUrl = process.env.NEXT_PUBLIC_FORMS_API_BASE_URL;
const baseApiDataVersion = "/data/v1/";
const baseApiLondonVersion = "/londonedu/v1/";

let token;
let LBSNumber;
let correlationId = uuidv4();

interface MakeRequestOptions {
  noStringifyBody?: boolean;
}

export const makeRequest = async (
  currentUrl,
  apiVersion,
  endpoint,
  method,
  headers?,
  body?,
  options: MakeRequestOptions = {}
) => {
  const { noStringifyBody } = options;

  const response = await fetch(`${currentUrl}${apiVersion}${endpoint}`, {
    method: method,
    headers:
      headers ||
      {
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Content-type": "application/json",
      },
    body: noStringifyBody ? body : JSON.stringify(body),
  });

  try {
    if (
      response.headers &&
      response.headers["x-lbs-correlation-id"] &&
      !correlationId
    ) {
      correlationId = response.headers["x-lbs-correlation-id"];
    }

    if (response && response.headers) {
      return response.headers.get("content-type")?.includes("application/json")
        ? await response.json()
        : await response.text();
    }
  } catch {
    return Promise.reject(response);
  }
};

export const setUser = (newToken, lbsNumber) => {
  token = newToken;
  LBSNumber = lbsNumber;
};

export const getPreferredDates = async (programmeTypeCode: string) => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    `programme/${programmeTypeCode}/preferreddates`,
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
    }
  );
};

export const getReasonForReservation = async () => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    "reason-for-reservation",
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
    }
  );
};

export const getSavedPages = async (accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/saved-content",
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const savePage = async (crm_campaign, Content, accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/saved-content${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    },
    { Content }
  );
};

export const removeSavedPage = async (Content, accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/saved-content`,
    "DELETE",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    },
    { Content }
  );
};

export const getApplications = async (accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/applications",
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const getPersonalDetails = async (accessToken?: string) => {
  return makeRequest(baseUrl, baseApiLondonVersion, "userprofile", "GET", {
    "Content-type": "application/json; charset=UTF-8",
    "x-lbs-correlation-id": `${correlationId}`,
    Authorization: `Bearer ${getCookie("access_token")}`,
  });
};

export const getUserInteractProfileDetails = async (accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/interact",
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const postContactForm = async (body, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `contact-us/consultant${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
    body
  );
};

export const setPersonalDetails = async (accessToken, details) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile",
    "PATCH",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    },
    details
  );
};

export const getProfileContactPreferences = async (accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/preference",
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const setProfileContactPreferences = async (accessToken, details) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/preference",
    "PATCH",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    },
    details
  );
};

export const setReserveAPlaceNotLoggedIn = async (body, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `programme/reserve-a-place${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body
  );
};

export const setReserveAPlaceLoggedIn = async (body, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/programme/reserve-a-place${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    body
  );
};

export const setEventBookingNotLoggedIn = async (body, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `event-booking${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body
  );
};

export const setEventBookingLoggedIn = async (body, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/event-booking${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    body
  );
};

export const setContactUsFormLoggedIn = async (
  body,
  crm_campaign,
  accessToken
) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/contact-us${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    },
    body
  );
};

export const setContactUsFormNotLoggedIn = async (body, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `contact-us${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body
  );
};

//Request Call Back

// Request Call back for loggedIn user
export const setRequestCallBackFormLoggedIn = async (
  crm_campaign,
  body,
  accessToken?: string
) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/callback-request${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    body
  );
};

// Request Call back for not loggedIn user

export const setRequestCallBackFormLoggedOut = async (crm_campaign, body) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `callback-request${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body
  );
};

//Register Interest//

// Request for loggedIn user
export const setRegisterInterestFormLoggedIn = async (
  crm_campaign,
  body,
  accessToken?: string
) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/register-interest${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    body
  );
};

export const setRegisterInteresFormLoggedOut = async (crm_campaign, body) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `register-interest${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body
  );
};

//End of Register Interest//

// Request for loggedOut user
export const setDownloadBrochureFormLoggedOut = async (crm_campaign, body) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `download-brochure${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body
  );
};

// Request for loggedIn user
export const setDownloadBrochureFormLoggedIn = async (
  crm_campaign,
  body,
  accessToken?: string
) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/download-brochure${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    body
  );
};

export const setSubmitCVLoggedOut = async (crm_campaign, body) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `submit-cv${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "x-lbs-correlation-id": `${correlationId}`,
    },
    body,
    { noStringifyBody: true }
  );
};

export const setSubmitCVLoggedIn = async (crm_campaign, body, accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/submit-cv${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    },
    body,
    { noStringifyBody: true }
  );
};

export const setConsultantForm = async (crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `contact-us-consultant-enquiries${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "POST"
  );
};

export const getProfileEmailSubscriptions = async () => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/profile-email-subscriptions",
    "GET"
  );
};

export const setProfileEmailSubscriptions = async (details) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/profile-email-subscriptions",
    "PUT",
    details
  );
};

export const activateAccount = async () => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/activate-account",
    "PUT"
  );
};

export const deactivateAccount = async (accessToken) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "userprofile/deactivate",
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${accessToken}`,
    }
  );
};

export const getInteractWebsiteLoginCredentials = async () => {
  return makeRequest(
    baseUrl,
    "/londonedu/v1/",
    "userprofile/interact-website-login-credentials",
    "GET"
  );
};

// PERSONAL DETAILS API
export const getTitles = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "title", "GET");
};

export const getProgrammesInterest = async (eventCode: string) => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    `campaign/${eventCode}`,
    "GET"
  );
};

export const getCountriesIso = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "country/alpha2code", "GET");
};

export const getSubjects = async () => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "contact-us-enquiries/categories",
    "GET"
  );
};

// categoryId is hardcoded due to isn't ready API
export const getTopics = async () => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "contact-us-enquiries/categories/100000001",
    "GET"
  );
};

export const getCountries = async (inputValue) => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    `country?term=${inputValue}`,
    "GET"
  );
};

export const getCountryStates = async (selectedCountry) => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    `country/${selectedCountry}/states`,
    "GET"
  );
};

export const getNations = async () => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    "personal-details/nations",
    "GET"
  );
};

export const getNationStates = async (state) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `personal-details/nations/${state}/states`,
    "GET"
  );
};

export const getGenders = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "gender", "GET");
};

export const getBroadIndustries = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "broad-industry", "GET");
};

export const getInstitutions = async () => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    "prerequisite-institution",
    "GET"
  );
};

export const getEEAndOnlineCourseList = async () => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    "execed-programme-type",
    "GET"
  );
};

export const getIndustries = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "industry", "GET");
};

export const getJobFunctions = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "job-function", "GET");
};

export const getJobLevel = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "job-level", "GET");
};

export const getCareerAspirations = async () => {
  return makeRequest(baseUrl, baseApiDataVersion, "career-aspiration", "GET");
};

export const getPreferredStudyFormat = async () => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    "preferred-study-format",
    "GET"
  );
};
export const getPreferredStudyLocation = async () => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    "preferred-study-location",
    "GET"
  );
};

export const getIntendedYearsOfStudy = async () => {
  return makeRequest(
    baseUrl,
    baseApiDataVersion,
    "intended-year-of-study",
    "GET"
  );
};

// programme-enquiry
export const setProgrammeEnquiry = async (formData, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `programme-enquiries${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    formData
  );
};

export const setUserProgrammeEnquiry = async (formData, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/programme-enquiries${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "POST",
    formData
  );
};

// Create profile
export const postCreateProfile = async (formData, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "x-lbs-correlation-id": `${correlationId}`,
    },
    formData
  );
};

//Subscribe
export const subscribeThink = async (formData, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `newsletter-subscription/gated-content/${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
    formData
  );
};

export const updateSubscribeThink = async (crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/newsletter-subscription/gated-content/${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${getCookie("access_token")}`,
    }
  );
};

export const gatedContent = async (formData, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `gated-content/${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    {
      "Content-type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
    formData
  );
};

export const gatedContentUser = async (formData, crm_campaign) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/gated-content${
      crm_campaign ? `?campaignCode=${crm_campaign}` : ""
    }`,
    "PUT",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    formData
  );
};

//Register
export const registerUser = async (params, crm_campaign) => {
  return makeRequest(
    baseUrl,
    "/londonedu/v1/",
    `userprofile${crm_campaign ? `?campaignCode=${crm_campaign}` : ""}`,
    "POST",
    params
  );
};

export const getUserHasBookedEvent = async (eventCode) => {
  return makeRequest(
    baseUrl,
    baseApiLondonVersion,
    `userprofile/campaign/${eventCode}/booked`,
    "GET",
    {
      "Content-type": "application/json; charset=UTF-8",
      "x-lbs-correlation-id": `${correlationId}`,
      Authorization: `Bearer ${getCookie("access_token")}`,
    }
  );
};
